import React, { useEffect } from "react";
import './App.css';
import CustomeRouter from './router/CustomeRouter.js';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation  } from 'react-router-dom'
import ScrollToTop from "./pages/scrollToTop/scrollToTop"

function App() {

  const location = useLocation()
    
    useEffect(() => {
        const preloader = document.querySelector('#preloader');
        if (preloader) {
          preloader.classList.remove('d-none')
          setTimeout(() => {
            preloader.classList.add('d-none')
          }, 1000)
        }
    },[location])
  
  return (
    <>
    <ScrollToTop />
      <CustomeRouter />
    </>
  );
}

export default App;
