import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";


const Home = React.lazy(() => import("../pages/home/home"));
const About = React.lazy(() => import("../pages/about/about"));
const Services = React.lazy(() => import("../pages/services/services"));
const Projects = React.lazy(() => import("../pages/projects/projects"));
const Infrastructure = React.lazy(() => import("../pages/infrastructure/infrastructure"));
const Blogs = React.lazy(() => import("../pages/blog/blog"));
const ContactUs = React.lazy(() => import("../pages/contact/contact"));
const ProjectDetail = React.lazy(() => import("../pages/projects/projectdetail"));
const ServiceDetail = React.lazy(() => import("../pages/services/servicedetail"));
const BlogDetail = React.lazy(() => import("../pages/blog/blogdetail"));
const Certification = React.lazy(() => import("../pages/information/Certification"));
const PackingDetails = React.lazy(() => import("../pages/information/packingDetails"));
const TechnicalSpecification = React.lazy(() => import("../pages/information/technicalSpecification"));
const Term = React.lazy(() => import("../pages/information/termCondition"));
const TilesCalculator = React.lazy(() => import("../pages/information/tilesCalculator"));
const Catalogue = React.lazy(() => import("../pages/catalogue/catalogue"));

// const Product = React.lazy(() => import("../../Component/product"));
// 
// const Catalogue = React.lazy(() => import("../blogs/catalogue"));
// const Services = React.lazy(() => import("../../Component/Service-us"));
// const Team = React.lazy(() => import("../team/team"));
// const Testimonial = React.lazy(() => import("../testimonial/testimonial"));

const CustomRouter = () => {
    return (
        <Routes>
            <Route path='/' element={<Suspense fallback={<></>}><Home /></Suspense>} />
            <Route path='/about' element={<Suspense fallback={<></>}><About /></Suspense>} />
            <Route path='/export' element={<Suspense fallback={<></>}><Services /></Suspense>} />
            <Route path='/products' element={<Suspense fallback={<></>}><Projects /></Suspense>} />
            <Route path='/products/:slug' element={<Suspense fallback=''><Projects /></Suspense>} />
            <Route path='/products/:slug/:pslug' element={<Suspense fallback={<></>}><ProjectDetail /></Suspense>} />
            <Route path='/news' element={<Suspense fallback={<></>}><Blogs /></Suspense>} />
            <Route exact path="/news/:slug" element={<BlogDetail />} />
            <Route path='/contact' element={<Suspense fallback={<></>}><ContactUs /></Suspense>} />
            <Route path='/products/:slug/:pslug' element={<Suspense fallback={<></>}><ProjectDetail /></Suspense>} />
            <Route path='/infrastructure' element={<Suspense fallback={<></>}><Infrastructure /></Suspense>} />
            <Route path='/service-details' element={<Suspense fallback={<></>}><ServiceDetail /></Suspense>} />
            <Route path='/news-details/:slug' element={<Suspense fallback={<></>}><BlogDetail /></Suspense>} />
            <Route path='/certification' element={<Suspense fallback={<></>}><Certification /></Suspense>} />
            <Route path='/packing-details' element={<Suspense fallback={<></>}><PackingDetails /></Suspense>} />
            <Route path='/technical-specification' element={<Suspense fallback={<></>}><TechnicalSpecification /></Suspense>} />
            <Route path='/terms-and-condition' element={<Suspense fallback={<></>}><Term /></Suspense>} />
            <Route path='/tiles-calculator' element={<Suspense fallback={<></>}><TilesCalculator /></Suspense>} />
            <Route path='/catalogue' element={<Suspense fallback={<></>}><Catalogue /></Suspense>} />
            {/* 
            
            
            <Route path='/catalogue' element={<Suspense fallback={<></>}><Catalogue /></Suspense>} />
            <Route path='/team' element={<Suspense fallback={<></>}><Team /></Suspense>} />
            <Route path='/testimonial' element={<Suspense fallback={<></>}><Testimonial /></Suspense>} /> */}
        </Routes>
    )
}

export default React.memo(CustomRouter);